 import React from 'react'
 import './footer.css'
 import { FaTwitter } from "react-icons/fa";
 import { FaFacebookF } from "react-icons/fa";
 import { FaInstagramSquare } from "react-icons/fa";
 import { FaLinkedin } from "react-icons/fa6";
 import { FaWhatsapp } from "react-icons/fa6";
 import { FaYoutube } from "react-icons/fa";

 const Footer = () => {
   return (
     <div className='footer'>



        <div className='footer-content'>
            <h1>Company</h1>
            <a href='/'>Home</a>
          <a href='/about'>  About us </a> 
       <a href='/Terms-and-Conditions'>   Terms & conditions </a>
       <a href='/privacy-policy'> Privacy policy </a>
       <a href='/Cancellation-Refund-Policy'> Cancellation & Refund Policy </a>
       {/* <a href='/UC impact'>   UC impact </a> */}
       {/* <a href='/Careers'>  Careers </a> */}
        </div>


        <div className='footer-content'>
            <h1>For customers</h1>

          {/* <a href='/UC reviews'>   UC reviews </a> */}
          {/* <a href='/Categories near you'> Categories near you </a>
          <a href='/Blog'> Blog </a> */}
          <a href='/Contact-us'> Contact us </a>

        </div>


        <div className='footer-content'>
            <h1>For partners</h1>
            <a href='https://play.google.com/store/apps/details?id=com.genius.servixoindia'>Register as a professional</a>
        </div>




<div>
  <h1>Social links</h1>
  
  <div className="logos">

  <a href='https://twitter.com/servixoIndia'><FaTwitter></FaTwitter></a> 
   <a href='https://www.facebook.com/profile.php?id=61558528480842'><FaFacebookF></FaFacebookF></a> 
  <a href='https://www.instagram.com/servixoindia?igsh=eDMxZjVncnF1OTB6'> <FaInstagramSquare></FaInstagramSquare></a> 
  <a href='https://www.linkedin.com/in/servixo-india-66436a306/'> <FaLinkedin></FaLinkedin></a> 
   <a href='https://whatsapp.com/channel/0029VaEn2Et8KMqenb0Jhv2B'><FaWhatsapp /></a>
   <a href='https://www.youtube.com/channel/UCOyjY2JcuiPWNoMJS8DZQJQ'><FaYoutube /></a>
  </div>
    
</div>

     </div>
   )
 }
 
 export default Footer