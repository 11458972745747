import React, { useState } from 'react';
import './Category.css';
import { MdUnfoldMoreDouble } from "react-icons/md";

const Categories = ({ data }) => {
  const [showAll, setShowAll] = useState(false);

  // Slice the categories based on whether to show all or just the first 10
  const categoriesToDisplay = showAll ? data.data.AllCategories : data.data.AllCategories.slice(0, 10);

  const base = "https://servixoindia.com/servixoindia/uploads/category/";



  function categoryData( a){
        
    // console.log("12345")
     return () => {
         console.log("Clicked category:", a);
         localStorage.setItem("categoryName",a.category_name);
         localStorage.setItem("categoryImage",a.category_image);
         localStorage.setItem("categoryName",a.category_name);
         localStorage.setItem("categoryId",a.id);
     };
 }



  return (
    <div className='data'>
      <p className='category-para'>Categories We Provide</p>
      <div className='container'>
        {categoriesToDisplay.map((category, index) => (
          <a href='/category' className='ram'>         
          <div key={index}    onClick={categoryData(category)}  >
            <div  className='category'>
              <img src={`${base}${category.category_image}`} alt=" " />
              <p > {category.category_name}</p>
            </div>
          </div>
          </a>
        ))}


        {(data.data.AllCategories.length > 10) && (
        <div onClick={() => setShowAll(!showAll)} className=' ' >
                
                <div className='show-all-conatainer'> 

        <MdUnfoldMoreDouble   className='show-all-icon'/>

          <p> {showAll ? 'Show Less' : 'Show All'} </p>
          
          </div>
        </div>
      )}
      </div>
      {/* Toggle button */}
       
    </div>
  );
};

export default Categories;