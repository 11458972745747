
import React, { useEffect, useState } from 'react';
import './Navbar3.css';
import img1 from '../ascets/SERVIXOINDIA-logo.png'
import { FaLocationDot } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";

const Navbar2 = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };


    const [dataFull,setData] =useState(null);

  const  [dataLoaded ,setdataLoaded]=useState(false);
 let abcd=false;
      let city;

 

      const fetchImages = async () => {
        try {
           // const urls = [];
            const url = 'https://ipinfo.io/json';
            const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjlkMDNiMDFkYmJiODI0MDQ5MjE0ZjQwNGFhOTk0OWM2ZGY2ODhlNjU1NGE3MmRkZmZmYTVkOTE0ODY1ZTZhNjA1NTQ1MzAzZThlOTJmZDAiLCJpYXQiOjE3MTA3MzY5MjUuNzY3ODUzLCJuYmYiOjE3MTA3MzY5MjUuNzY3ODU2LCJleHAiOjE3NDIyNzI5MjUuNzYzNzQ0LCJzdWIiOiIxNSIsInNjb3BlcyI6W119.Nvhqx5jq99VQyiXbpbUa1kEPYmnfoxwgjepWQBXkwUDSUAn15tr7XMXtvP1gUZA331j9gcHY3daZoi30MGEil62CGD4HjokWVPqVVv0OK_KQY-DBM1XO5HYACQIO4zV2-vgiFJhkwEOlj12J8G6YJhqgbxevGWsgPKe3SV-QXiGBnlaHZDtx7lI5YZxYsH7FHbA_NryH-ZP45JAyaWBFL5eJL3xwrvdt1U8EmQwisPFejoyFW5LS2_FoMUw6GtpMS6hIBuGENBMLxL6WZWbTXAaBdgu44xwNOYEa4XdOec9eJyAAWN9SSbQas-B5tFHVqJvWgiGMF6r-eQn_jh2JKdY0NeF5g7zD9O5Np9Dj3igBh2okTvpzy7nbJFECTwuPjacpQzTHAPV3OBp9Ebu__dXEanXE9IoPoNzDcgls9MrrfJsoBh44V5Fd6UhcZmZj05u-U0Jw-VV_0wLo5dXM9FwwIMPVpcqA7SCakJMDmwTtFaApgM3ZMlR2dMjc67C1i0u-IJMVm_HAzPktxCuA6T1f_g_vfawHdtnjbNEEXNyyQWs75oMveDowSTVIbN6ZiyBitFAbJd9kT2KIfs1Eo-25NKNFVQO0T--WOng_ZGepvDye9JxzzOyvgBCGrizqDRVVZFBRag44LbNaZzdG_g-7-isdyblPBZ6HuUSSYDk';
            const requestData = {
                method: 'GET',
               
            };
    
            const response = await fetch(url, requestData);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            
           
           city=await data.city;

           console.log("122",city);
 
           setData(data)
           setdataLoaded(true);
            return data;
    
            
        } catch (error) {
            console.error('Error fetching image URLs:', error);
        }
    };
    


      useEffect(() => {
        fetchImages();
    }, []);
 


    function newsearch(){
        let d=document.getElementById("new-search");
        
        localStorage.setItem("abcd", d.value );
        console.log(d.value )
        
    }



    return (
        <div>

          <div className='mobile-search'>
          <div className='input-icon-search'>

           
                   <input type='text' placeholder='enter the category or service ....' id='new-search'></input>

               <a  href='/search'><FaSearch className='new-search' onClick={newsearch}></FaSearch></a>    
               </div>
                
          </div>



        
            <nav>
                <ul className={sidebarVisible ? "sidebar visible" : "sidebar hidden"} id='a'>
                    <li onClick={hideSidebar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 96 960 960" width="26"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg></a></li>
                    <li><a href="/" onClick={hideSidebar}>home</a></li>
                    <li><a href="/about" onClick={hideSidebar}>About  Us</a></li>
                    <li><a href="/services" onClick={hideSidebar}>Services</a></li>
                     
                    <li><a href="/Contact-us"  onClick={hideSidebar}>Contact Us</a></li>
                </ul>
                <ul className={sidebarVisible ? "tabs hidden" : "tabs"}>
                   
                    
                    <li><a href="#">  <img src={img1}  className='image' alt=''></img></a></li>

                               
                               
                                        {/* <li id='search'>

                                        <div   className='box-location'  >
                                      
                                      <input type='text' placeholder='search-here... ' className='input-search'></input>

                                                    </div>
                    </li>
 */}



                    <li className="hideOnMobile"  ><a href='/'>  Home </a></li>
                    <li className="hideOnMobile"><a href="/about">About us</a></li>
                    <li className="hideOnMobile"><a href="/services">Services</a></li>
                   
                    <li className="hideOnMobile"><a href="/Contact-us">Contact Us</a></li>
                    <li className="menu-button" onClick={showSidebar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 96 960 960" width="26"><path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z"/></svg></a></li>
                </ul>
            </nav>
        </div>
    );
}

export default Navbar2;

