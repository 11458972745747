 
import './App.css';
 
 
import Home from './components/Home';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditionsFunction from './components/PrivacyPolicy/TermsAndCondition';
import CancellationRefundPolicyFunction from './components/PrivacyPolicy/Cancellation-Refund-Policy';
import ContactUsFunction from './components/PrivacyPolicy/ContactUs';
import Search from './Search/Search';
import Aboutus from './Aboutus/Aboutus';
import CategoryServices from './Category/CategoryServices';
import Services from './services/Services';
 import Counter from './Dummy'
import SendQuery from './SendMessage/SendQuery';
import GoogleTranslate from './GoogleTranslate/GoogleTranslate';
 
 
 
 
  

function App() {
 

  return (
    
    <div >
      
      
             {/* <Routes>
        <Route path='/' element={  <Home/> } />
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
        <Route path='/Terms-and-Conditions' element={ <TermsAndConditionsFunction/>}></Route>
        <Route path='/Cancellation-Refund-Policy' element={ <CancellationRefundPolicyFunction/>}></Route>
        <Route path='/Contact-us' element ={ <ContactUsFunction/>}></Route>
        <Route path='/search' element={<Search></Search>}></Route>
        <Route path='/about' element={<Aboutus></Aboutus>}></Route>
        <Route path='/category' element={<CategoryServices></CategoryServices>}></Route>
        <Route path='/services' element={<Services></Services>}></Route>
      </Routes>        */}

      {/* <Counter></Counter>

           
    
    
     
   {/* <Search></Search> */}
     {/* <Aboutus></Aboutus> */}
     {/* <Aboutus></Aboutus> */}

     {/* <Search></Search> */}

     {/* <CancellationRefundPolicyFunction></CancellationRefundPolicyFunction> */}

    {/* <ContactUsFunction></ContactUsFunction> */}

    
    {/* <Search></Search> */}

{/*  
 <CategoryServices></CategoryServices> */}
{/* 
 <Services></Services> */}

{/* <CancellationRefundPolicyFunction></CancellationRefundPolicyFunction> */}
 
  {/* <Aboutus></Aboutus> */}

  {/* <DeviceAddress></DeviceAddress> */}

<Home></Home>

{/* <SendQuery></SendQuery> */}
 {/* <TermsAndConditionsFunction></TermsAndConditionsFunction> */}
  
  {/* <PrivacyPolicy></PrivacyPolicy> */}


  {/* <GoogleTranslate></GoogleTranslate> */}
    </div>
  );
}

export default App;
