import React from 'react'
import './Events.css'

const EventData = ({data}) => {

console.log("ok");
const events = data.data.Events;

const maxId = Math.max(...events.map(event => event.id));
const filteredEvents = events.filter(event => event.id === maxId);
console.log("mera data ",data.data.Events ,maxId, filteredEvents)
  return (
    <div>
       
       
       <div  className='event-container'>

          
       <div  >
            {
                filteredEvents.map((events,index)=>(
                    <div className='event-para' key={index}>
                    <h1>{events.name}</h1>
                    <p>{events.description}</p>
                    <button>Explore More</button>
                    </div>
                       

                ))
            }
           </div>


          <div className='event-image'>


          {
            filteredEvents.map((event,index)=>(
                <div key={index}>'
         

                  <img src={`${data.data.EventImageURL}/${event.event_image}`} alt=''></img>

                </div>
            ))
        }


          </div>


            




       </div>
       
       

    </div>
  )
}

export default EventData